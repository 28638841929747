import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  return (
    <Navbar expand='sm' style={{ backgroundColor: 'transparent' }}>
      <Container>
        <Navbar.Brand href="#"><img
          src={`${process.env.PUBLIC_URL}/BCB.png`}
          alt='ByteCanvas'
          style={{ width: '150px', height: 'auto' }} // Adjust the width to your preference
        /></Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse className='justify-content-end'>
          <Nav className="me-auto">
            <Nav.Link href="#/">Home</Nav.Link>
            <Nav.Link href="#/works">Works</Nav.Link>
            <Nav.Link href="#/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
