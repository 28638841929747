import React, { useState, useEffect } from 'react';
import Header from '../../components/header';
import { Container } from 'react-bootstrap';
import Footer from '../../components/footer';
import Card from 'react-bootstrap/Card';
import Floating from '../../components/floating';
import Loading from '../../components/loading';

function WorksPage() {
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const handleLoad = () => setIsLoading(false);
    
    const images = [
      `${process.env.PUBLIC_URL}/millennial-asia-businessmen-businesswomen-meeting-brainstorming-ideas-about-new-paperwork-project-colleagues-working-together-planning-success-strategy-enjoy-teamwork-small-modern-night-office.jpg`,
      `${process.env.PUBLIC_URL}/latto.png`,
      `${process.env.PUBLIC_URL}/Ucbeauty.png`,
      `${process.env.PUBLIC_URL}/image.png`
    ];

    let loadedImages = 0;

    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          handleLoad();
        }
      };
      img.onerror = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          handleLoad();
        }
      };
    });
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div style={{ minHeight: '100vh' }}>
        <Header />
        <div style={{
          position: 'relative',
          height: '250px',
          marginBottom: '30px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 30px 0px 30px',
        }}>
          <img
            src={`${process.env.PUBLIC_URL}/millennial-asia-businessmen-businesswomen-meeting-brainstorming-ideas-about-new-paperwork-project-colleagues-working-together-planning-success-strategy-enjoy-teamwork-small-modern-night-office.jpg`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '-1',
              filter: 'brightness(70%)',
            }}
            alt="background"
          />
          <div style={{ color: 'white', zIndex: '1', textAlign: 'center' }}>
            <h1 className="text-center">Our Works</h1>
            <p className="text-center lead">We'd love to hear from you!</p>
          </div>
        </div>
        <div className="container">
          <Container>
            <div className="row justify-content-center">
              <div className="col-sm-6 col-md-4 mb-4">
                <Card className="text-center">
                  <Card.Img variant="top" style={{ aspectRatio: "16/9", objectFit: 'contain', backgroundColor: 'black' }} src={`${process.env.PUBLIC_URL}/latto.png`} />
                  <Card.Body>
                    <Card.Title>Latto</Card.Title>
                    <Card.Text>
                      We made a design prototype for a laptop store e-commerce website named Latto.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-sm-6 col-md-4 mb-4">
                <Card className="text-center">
                  <Card.Img variant="top" style={{ aspectRatio: "16/9", objectFit: 'contain', backgroundColor: 'black' }} src={`${process.env.PUBLIC_URL}/Ucbeauty.png`} />
                  <Card.Body>
                    <Card.Title>UC Beauty</Card.Title>
                    <Card.Text>
                      We made a skincare product showcase website named UC Beauty.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-sm-6 col-md-4 mb-4">
                <Card className="text-center">
                  <Card.Img variant="top" style={{ aspectRatio: "16/9", objectFit: 'contain', backgroundColor: 'black' }} src={`${process.env.PUBLIC_URL}/image.png`} />
                  <Card.Body>
                    <Card.Title>Digiwallet</Card.Title>
                    <Card.Text>
                      We made a design prototype for digital wallet mobile application named Digiwallet.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>

            <h2 className="text-center mb-4 mt-5">Interested in Our Services?</h2>
            <p className='text-center lead mb-4'>Ready to kick-start your project or need more information about our services? Fill out the form, and our representatives will get back to you shortly.</p>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <p className="lead">
                <a href="#" className="btn btn-lg btn-primary fw-bold" style={{ borderRadius: '1rem' }}>Contact Us</a>
              </p>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
      <Floating />
    </>
  );
}

export default WorksPage;
