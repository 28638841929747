import React, { useState, useEffect } from 'react';
import Header from "../../components/header";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Footer from "../../components/footer";
import Floating from "../../components/floating";
import { useRef } from "react";
import emailjs from '@emailjs/browser'
import Loading from '../../components/loading';

function ContactPage() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (document.getElementById("name").value){
      if (document.getElementById("email").value){
        if (document.getElementById("msg").value){
          emailjs.sendForm('service_w8ofl18', 'template_4mc3quo', form.current, 'Rsjv6VO_UXmfED-tt')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          document.getElementById("form").reset();
          alert('Sent!')
        } else alert("Message cannot be empty!")
      } else alert("Email cannot be empty!");
    } else alert("Name cannot be empty!");
  };

  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const handleLoad = () => setIsLoading(false);
    
    const images = [
      `${process.env.PUBLIC_URL}/side-view-cropped-unrecognizable-business-people-working-common-desk.jpg`,
    ];

    let loadedImages = 0;

    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          handleLoad();
        }
      };
      img.onerror = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          handleLoad();
        }
      };
    });
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
        }}
      >
        <Header />
        <div style={{
          position: 'relative',
          height: '250px',
          marginBottom: '30px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 30px 0px 30px',
        }}>
          <img
            src={`${process.env.PUBLIC_URL}/side-view-cropped-unrecognizable-business-people-working-common-desk.jpg`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '-1',
              filter: 'brightness(60%)',
            }}
            alt="background"
          />
          <div style={{ color: 'white', zIndex: '1', textAlign: 'center' }}>
            <h1 className="text-center">Contact Us</h1>
            <p className="text-center lead">We'd love to hear from you!</p>
          </div>
        </div>
          <Form ref={form} id="form" onSubmit={sendEmail}>
            <Container>
            <Row as={'div'}>
              <Col xs={12} sm={4}>
                <h2>Get in Touch with ByteCanvas</h2>
                <p>At ByteCanvas, we're committed to providing top-notch web and application development services tailored to your needs.</p>

                <h2>Have a Project in Mind?</h2>
                <p>Ready to kick-start your project or need more information about our services? Fill out the form, and our representatives will get back to you shortly.</p>   
              </Col>

              <Col xs={12} sm={8}>
                  <Form.Group controlId="formBasicName" style={{marginBottom: '20px'}}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" id="name" name="user_name"/>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail" style={{marginBottom: '20px'}}>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" id="email" name="user_email"/>
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="formBasicMessage" style={{marginBottom: '20px'}}>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter your message"
                      style={{resize:'none'}}
                      id="msg"
                      name="user_msg"
                    />
                  </Form.Group>

                  <div style={{
                    width:'100%',
                    display: 'flex'
                  }}>
                    <Button variant="primary" type="submit" style={{width: '100%'}}>
                      Submit
                    </Button>
                  </div>
              </Col>
            </Row>
            </Container>
          </Form>
        <Footer />
      </div>
      <Floating/>
    </>
  );
}

export default ContactPage;
