import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="pt-4 my-md-5 pt-md-5 border-top text-center">
      <Container>
        <Row>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <small className="d-block mb-3 text-secondary">Copyright ByteCanvas &copy; 2024</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
