import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }}>
    <Spinner animation="border" variant='primary' role="status">
    </Spinner>
  </div>
);

export default Loading;