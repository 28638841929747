import { HashRouter,Routes, Route} from 'react-router-dom';
import './App.css';
import './bootstrap/bootstrap.min.css'

//Components Import
import HomePage from './assets/pages/homePage';
import WorksPage from './assets/pages/worksPage';
import ContactPage from './assets/pages/contactPage';

function App() {
  return (
    <HashRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />}/>
          <Route exact path="/works" element={<WorksPage />}/>
          <Route exact path="/contact" element={<ContactPage />}/>
        </Routes>
    </HashRouter>
  );
}

export default App;
