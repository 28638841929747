import React from 'react';
import { Button } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon
import '../../../bootstrap/bootstrap.min.css'

function Floating() {
  const handleClick = () => {
    window.location.href = 'https://api.whatsapp.com/send/?phone=6285171532400&text=I%27m+interested+in+your+services&type=phone_number&app_absent=0';
  };

  return (
    <Button
      variant="primary"
      style={{ 
        borderRadius: '50%', 
        width: '60px', 
        height: '60px', 
        fontSize: '24px',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={handleClick}
    >
      <FaWhatsapp style={{ width: '70%', height: '70%' }} />
    </Button>
  );
}

export default Floating;
